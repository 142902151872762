@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .roundquarters {
    border-bottom-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;

    }
}
/* html,
body {
  width: 100%;
  height: 100%;
  @apply flex;
}

#root {
  @apply flex flex-1;
} */